.let_connect_icon {
  height: 80px;
  width: 100px;
}
.mian_cont {
  background-color: #ffffff;
  font-family: "Figtree", sans-serif !important;
}
.head {
  font-size: var(--dh2);
  margin: 0.1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 600;
}

.contact_img {
  display: flex;
  justify-content: center;
  gap: 7rem;
}

.contact_img p {
  font-size: var(--dp);
  font-weight: 600;
}

.connect_point {
  font-size: var(--dh3);
  margin: 0;
}

.mian_cont {
  text-align: center;
}

.img_div1 {
  text-align: center;
}

.social img {
  height: 30px;
  width: 30px;
}

.partner-logos img {
  height: 80px;
  width: 50px;
  border-radius: 80px;
}
.course {
  display: flex;
}

.tag {
  margin: 50px;
  text-align: center;
}

@media screen and (max-width: 769px) {
  .contact_img {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    margin-top: 1rem;
  }

  .head {
    font-size: var(--mh2);
    font-weight: 600;
    padding-top: 2rem;
  }
  .connect_point {
    font-size: var(--mh3);
  }

  .contact_img p {
    font-size: var(--mp);
  }
}
