.coursecard-main {
  display: flex;
  margin: 3rem 0 0 3rem;
  font-family: "Figtree", sans-serif !important;

  & .coursecard-heading {
    padding: 1rem;
    width: auto;
    margin-left: 1rem;

    & h2 {
      font-size: 2rem;
      text-align: center;
      padding: 0rem;
      margin: 0rem;
    }
  }

  & .coursecard-header {
    & h2 {
      font-size: 1.6rem;
      padding: 1rem 2rem 0 2.7rem;
      font-weight: 700;
    }
  }

  & .course {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & .course-container {
      background-color: #ffffff;
      height: auto;
      border-radius: 1rem;
      padding: 1rem 2rem;
      margin: 2rem;

      & h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }

      & p {
        font-size: 1.2rem;
      }

      & button {
        background-color: #ffffff;
        margin: 1rem 0;
        font-size: 1.3rem;
        padding: 0 0.5rem;
        border: none;
        border-bottom: 2px solid
          linear-gradient(180deg, #50abff 0%, #00315e 100%);
      }

      & button:hover {
        background: linear-gradient(180deg, #50abff 0%, #00315e 100%);
      }
    }
  }
}

.display-mobile {
  display: none;
}

* {
  margin: 0;
  padding: 0;
}

@media (width <=575px) {
  .display-mobile {
    display: block;
  }

  .coursecard-main {
    display: none;
  }
}
