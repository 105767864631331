.sidebarcourse-main {
  background-color: #c3fffa;
  width: 20vw;
  font-family: "Figtree", sans-serif !important;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  border-radius: 1rem;

  & button {
    width: 100%;
    margin: 0.2rem;
    height: 5rem;
    padding-left: 1rem;
    background-color: #c3fffa;
    border: none;
    text-align: left;
    font-size: 0.95rem;
    cursor: hand;
    cursor: pointer;
    text-align: center;
    border-bottom: 0.1rem #263238 solid;
    color: #000000;
  }
}

button:hover {
  background-color: #333333;
  color: white;
}
