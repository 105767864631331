*{
  font-family: "Figtree", sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-optical-sizing: auto;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

:root {
  --dh2: 3rem;
  --dh3: 1.5rem;
  --dp: 1.2rem;
  --mh2: 2.4rem;
  --mh3: 1.5rem;
  --mp: 1.6rem;
}
