.adminPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    .adminPanel_container {
        & form {
            display: flex;
            flex-direction: column;
        }
    }
}

.enq-container {
    display: flex;
    flex-direction: column;

    & table,
    th,
    td {
        border: 1px solid black;
    }
}