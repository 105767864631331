.navbar {
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem 1rem 1rem 0;
  background-color: #c3fffa;
  font-family: "Figtree", sans-serif !important;
}

nav {
  line-height: 2rem;
}

.scroll-animation {
  animation-name: scrollEffect;
  animation-duration: 1s;
}

@keyframes scrollEffect {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.logo {
  & img {
    width: 12rem;
    padding: 1.3rem 0 1rem 0;
  }
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-item {
  font-size: 1rem;

  & .submenu {
    position: absolute;
    visibility: hidden;
    z-index: 1;
    background-color: #c3fffa;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;

    & .sub-menus {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
    }

    & .parent-nav-heading {
      display: flex;
      align-items: flex-start;

      & p {
        padding: 0 1rem;
        width: 15rem;
        border-bottom: black solid 1px;
      }

      & .subheading-manager {
        position: relative;
      }
    }

    & .child-nav-subheading {
      display: none;
      background-color: white;
      width: 100%;
      overflow: scroll;

      & li {
        border-bottom: black solid 1px;
      }

      & li:hover {
        background-color: #c3fffa;
      }
    }
  }
}

.submenu a {
  color: black;
}

li:hover ul.submenu {
  visibility: visible;
}

.parent-nav-heading:hover .child-nav-subheading {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 15rem;
  height: 100%;
}

.child-nav-subheading {
  columns: 2;
}

.submenu li p:hover {
  /* width: 12rem; */
  background-color: black;
  color: white;
}

.nav-item a {
  color: #000000;
  font-size: 1.2rem;
}

.hamburger {
  display: none;
}

.signin-button {
  background: linear-gradient(180deg, #50abff 0%, #00315e 100%);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem;
}

.nav-item-wrapper {
  display: flex;
  align-items: center;
}

.nav-item-wrapper a {
  margin-right: 0.5rem;
  /* Adjust the margin as needed */
}

@media screen and (max-width: 940px) {
  .nav-menu {
    position: fixed;
    top: 0;
    z-index: 1;
    /* left: -100%; */
    top: -100%;
    flex-direction: column;
    background-color: #c3fffa;
    width: 50%;
    height: 50%;
    text-align: center;
    transition: 0.4s;
    padding-top: 6rem;
    left: 50%;
  }

  .nav-menu.active {
    /* left: 0; */
    top: 0;
  }

  .nav-item {
    margin: 0.8rem;
    font-size: 1rem;
    justify-content: center;

    & .signin-button {
      padding: 8px 2px;
      font-size: 1rem;
    }
  }

  .nav-item a {
    font-size: 0.8rem;
    padding: 0;
  }

  .hamburger {
    display: flex;
    z-index: 1;
    color: black;
  }

  .navbar {
    & .nav-menu {
      & li:hover ul.submenu {
        visibility: hidden;
      }
    }
  }
}

.enquiryForm-container {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-content {
  background-image: url("./../assests/form-bg-img.jpg");
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .Enquiry-form {
    height: 60vh;

    & h2 {
      text-align: center;
      padding: 0px;
      margin: 0px;
      padding-bottom: 2vh;
      font-weight: 500;
      font-family: sans-serif;
    }

    & form {
      & .input {
        display: flex;
        justify-content: center;

        & input {
          padding-left: 1rem;
          font-size: 1rem;
          background-color: white;
          width: 30rem;
          border: none;
          margin: 0.5rem;
          background-color: #fff;
          border: 1px solid #ccc;
        }
      }
    }

    & .submit {
      display: ruby-text;

      & button {
        padding: 1rem;
        font-size: 1rem;
        margin: 1rem;
        background-color: white;
        border: none;
        border-radius: 2rem;
        width: 20rem;
        background-color: #fff;
        border: 1px solid #ccc;
      }

      & button:hover {
        background-color: black;
        color: white;
      }
    }
  }

  ::placeholder {
    color: black;
  }
}

.close-button {
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 1rem;

  & button {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ccc;
  }
}

@media (width <=750px) {
  .form-content {
    width: 80vw;

    & .Enquiry-form {
      padding: 5rem;

      & form {
        width: 20rem;

        & .input {
          & input {
            width: 80%;
            background-color: #fff;
            border: 1px solid #ccc;
          }
        }
      }

      & .submit {
        & button {
          width: 9rem;
          background-color: #fff;
          border: 1px solid #ccc;
        }
      }
    }
  }
}
