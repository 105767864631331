.place_main {
  display: flex;
  justify-content: start;
  margin-left: 2.4rem;
  gap: 1rem;
  align-items: start;
  text-align: center;
  overflow-x: hidden;
  margin-top: 2rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Figtree", sans-serif !important;
}
#place_text {
  width: 75%;
  text-align: center;
  color: #455a64;
  font-family: "Lato", Sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
}
.place_main_sub {
  font-size: 40px;
  padding-bottom: 1rem;
}
.place_main_sub img {
  height: 30rem;
}

.slider-scroll {
  background: transparent;
  margin-top: 1rem;
  margin-bottom: 1rem;
  & .image_wrapper {
    & img {
      background: transparent;
      height: 5rem;
    }
  }
}

.image_wrapper {
  margin: 0px 30px;
}

.image_wrapper img {
  width: 100%;
  height: 10rem;
}

.stu_placed {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding-bottom: 5rem;
}
.stu_placed h2 {
  font-size: 41px;
  font-family: sans-serif;
  color: #455a64;
}
.stu_placed p {
  font-size: 20px;
  font-family: sans-serif;
  color: #212529;
}

.email_con {
  width: 85%;
  text-align: center;
  margin: auto;
  font-size: 30px;
  padding: 10px;
  margin-bottom: 5rem;
  line-height: 42px;
  background-color: #ffd51954;
  font-family: sans-serif;
  border-radius: 15px;
}

@media screen and (max-width: 1024px) {
  .place_main_sub img {
    height: auto;
  }
  .place_main_sub p {
    font-size: 19px;
  }

  .place_main_sub img {
    width: 65%;
  }

  .stu_placed {
    flex-direction: column;
  }
  .email_con {
    font-size: 16px;
  }
  .stu_placed h2 {
    font-size: 30px;
  }

  #place_text {
    width: 80%;
    line-height: 20px;
  }
  .marquee {
    height: 80px;
    width: 80px;
  }

  .stu_placed p {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  .place_main {
    width: 90%;
    & .place_main_sub {
      & p {
        width: 10rem;
      }
    }
  }

  .stu_placed {
    padding: 1rem;
  }
  .place_main_sub p {
    font-size: 14px;
  }
  .place_main {
    margin: 1rem;
  }
  .slider-scroll {
    margin-top: 2rem;
    & .image_wrapper {
      & img {
        height: 5rem;
      }
    }
  }
  .email_con {
    margin: 1.7rem;
  }
}
