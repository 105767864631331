.faq-container {
    padding: 0 15px;
}


.faq {
    border-radius: 10px;
    padding-bottom: 1rem;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    transition: all .4s ease;
    font-size: 15px;
    
}


.faq.active {
    /* overflow: scroll; */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}


.faq-title {
    margin: 0 35px 0 0;
}


.faq-text {
    display: none;
    margin: 30px 0 0;
    font-size: larger;
}


.faq.active .faq-text {
    display: block;
}



.faq-toggle img {
    width: 3rem;
    height: 3rem;
}

.faq-toggle .close {
    display: none;
}

.faq.active .faq-toggle .close {
    display: block;
}

.faq.active .faq-toggle .chevron {
    display: none;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 2rem;
  }

.down{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.faq.active {
    & .question-format {
        & .drop-head {
            background-color: black;
            color: white;
            padding: 1.5rem;
        }
    }
}

.drop-head {
    display: flex;

    & h2 {
        font-size: 1rem;
    }
}

.Mobile-Card {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    background-color: white;
    border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    -moz-border-radius: 2.5rem;
    -ms-border-radius: 2.5rem;
    -o-border-radius: 2.5rem;

    & h2 {
        font-size: 1.2rem;
        font-weight: 800;
        
    }

    & p {
        font-size: 1rem;
        text-align: justify;
    }

    .Mobile-Card-Button {
        margin-top: 1rem;
        text-align: left;
        font-size: 1.2rem;
        border-bottom: solid black .1rem;
    }

    .Mobile-Card-Button:hover {
        cursor: pointer;
        background-color: black;
        color: white;
    }
}

.mobile-course{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}