.course-main {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  color: black;
  text-align: center;
  padding-top: 1rem;
  font-family: "Figtree", sans-serif !important;

  & .sep {
    font-size: 3rem;
    text-align: center;
    border-bottom: 0.1rem black solid;
    display: inline;
  }

  & .course-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: stretch;
    margin-top: 2rem;

    & .course-card {
      background-color: #c3fffa;
      width: 21rem;
      height: 40rem;
      border-top-right-radius: 2rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      & img {
        opacity: 0.8;
        border-top-right-radius: 2rem;
        border-top-left-radius: 2rem;
      }

      & .endpart {
        display: flex;
        flex-direction: column;
        height: 19rem;
        justify-content: space-between;
      }
    }

    & .course-content {
      padding: 1rem;
      display: flex;
      color: black;
      text-align: left;
      height: 11rem;
      flex-direction: column;
      justify-content: space-around;

      & h2 {
        font-size: var(--dh3);
        margin:0;
      }

      & p {
        font-size: var(--dp);
        font-style: italic;
      }
    }

    & .course-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: start;
      padding: 1rem;

      & button {
        font-size: 1rem;
        border: none;
        outline: none;
        margin: 0.2rem;
        padding: 0.2rem;
        pointer-events: none;
        cursor: not-allowed;
        background-color: #c3fffa;
      }
    }

    .course-read {
      background: linear-gradient(180deg, #50abff 0%, #00315e 100%);
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      & .know {
        background: #00315e00;
        border: none;
        margin: 0.5rem;
        font-size: 1rem;
        color: white;
      }
    }
  }
}

.view-more {
  font-size: 1.7rem;
  background: linear-gradient(180deg, #50abff 0%, #00315e 100%);
  margin: 2rem;
  padding: 0.8rem;
  border-radius: 1rem;
  border: 0 #ede4d7;
  color: white;
  font-family: "Figtree", sans-serif !important;
}

.know:hover {
  color: black;
}

button {
  cursor: pointer;
}

@media screen and (min-width: 120px) and (max-device-width: 768px) {
  .course-main {
    padding-top: 1rem;

    & .sep {
      font-size: 2.2rem;
    }
  }

  .view-more {
    font-size: 1.2rem;
  }
}
