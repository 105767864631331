.coursedetails-main {
  & .coursedetails-header {
    color: #f1f1ec;
    width: 100%;
    height: calc(100vh - 85px);
    background-color: rgba(0, 0, 0, 0.404);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-image: url(../assests/Blur.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Figtree", sans-serif !important;

    & h2 {
      font-size: 5.2rem;
      margin: 0;
      padding: 2rem;
    }

    & p {
      font-size: 1.5rem;
      font-style: italic;
      margin: 0.5rem 2.4rem;
    }

    & .enroll {
      background-color: #ede4d7;
      padding: 1.4rem;
      font-size: 1.2rem;
      border: none;
      border-radius: 5rem;
      margin: 2rem;
    }
  }

  & .coursedetails-learn {
    background-color: white;

    & .learn-head {
      display: flex;
      justify-content: space-between;
      padding: 2rem 9rem;
      align-items: center;
    }

    & .tick-learn ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      text-align: left;
      padding: 0rem 8rem;
      line-height: 2rem;

      & li {
        font-size: 1.2rem;
        text-align: justify;
      }
    }
  }

  & .coursedetails-process {
    background-color: white;

    & .learn-process {
      display: flex;
      justify-content: space-between;
      padding: 2rem 9rem;
      align-items: center;
    }

    & .process-content-images {
      display: flex;
      justify-content: center;
      padding: 2rem 9rem;

      & .content-lap {
        width: 100%;
      }

      & .content-mob {
        display: none;
        width: 80%;
      }
    }

    & .process-content {
      padding: 2rem 8rem;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      & .arrow-side {
        display: flex;
        align-items: center;
      }

      & .image-title {
        text-align: center;

        & img {
          margin: 1.2rem;
        }
      }

      & IoArrowForward {
        width: 5rem;
      }

      & .bottom-process-images {
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        justify-content: center;
        align-items: center;

        & .arrow-side {
          & svg {
            transform: rotate(180deg);
          }
        }
      }

      & .up-process-images {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & .coursedetails-highlights {
    width: 100%;
    height: auto;
    background-image: url("./../assests/Blur.png");
    background-repeat: no-repeat;
    background-size: cover;

    & .highlight-bg {
      background-color: rgba(0, 0, 0, 0.74);
      height: auto;

      & .highlights-heading {
        display: flex;
        justify-content: space-between;
        padding: 1rem 5rem;
        align-items: center;

        & h2 {
          color: white;
        }
      }

      & .highlights-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        padding: 1rem 3rem;

        & .highlights-left-content {
          color: white;
          border-radius: 1rem;
          margin: 0 3rem 0.5rem 3rem;
          align-content: center;
          align-items: center;
          columns: 2;
          border: 0.1rem white solid;
          height: 100%;
          padding: 0 3rem;
          padding-top: 2rem;

          & ul li {
            background-image: url("./../assests/gear.png");
            background-size: 1.6rem;
            background-repeat: no-repeat;
            line-height: 1.5rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            list-style-type: none;
          }
        }

        & .highlights-right-content {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;

          & .duration {
            color: white;
            border: 0.1rem white solid;
            text-align: center;
            padding: 0.5rem;
            margin: 0.5rem;
            border-radius: 1rem;
            width: 39%;
          }
        }
      }
    }
  }

  & .coursedetails-course-content {
    background-color: white;
    height: auto;

    & .coursedetails-content-header {
      padding: 1rem 9rem;
      text-align: center;
    }

    & .course-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .course-css {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 1rem 0;
        border-radius: 8px;
        background-color: #c3fffa;
        width: 40vw;
        padding: 2rem;
        columns: 2;

        & h2 {
          margin-top: -1rem;
          text-align: center;
          font-size: 2rem;
        }

        & ul li {
          list-style: disc;
          margin-left: 1rem;
          font-size: var(--dp);
        }
      }

      &.course-two {
        position: relative;
        display: flex;
        margin: 20px 0;
        width: 500px;
        margin-left: 350px;
        border-radius: 8px;

        & .line_two {
          position: absolute;
          top: 20%;
          left: -345px;
          z-index: 2;
          height: 12rem;
          background-color: #ffc727;
          width: 160px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 72px;
          color: black;
          transition-duration: 0.4s;
          transform: scale(0, 0);
          text-align: center;
          font-weight: 900;
        }
      }
    }
  }

  & .course-skills {
    background-color: white;

    & .skills-heading {
      display: flex;
      justify-content: space-between;
      padding: 2rem 9rem;
      align-items: center;
    }

    & .coursedetails-button {
      display: flex;
      justify-content: flex-start;
      padding: 0rem 9rem;
      flex-wrap: wrap;

      & button {
        padding: 1.4rem;
        margin: 1rem;
        border: none;
        background-color: E5E7E8;
        cursor: pointer;
        border-radius: 0.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: #333333;
        pointer-events: none;
        cursor: not-allowed;
        box-shadow: none;
      }

      & button:hover {
        color: rgb(255, 255, 255);
      }
    }
  }

  & .coursedeatils-certificate {
    background-color: white;

    & .certificate-heading {
      padding: 1rem 9rem;

      & h2 {
        font-size: var(--dh2);
      }
    }

    & .certificate-content {
      display: flex;
      justify-content: space-between;
      padding: 1rem 9rem;
      align-items: center;
      flex-wrap: wrap;

      & .certificate-left {
        & p {
          font-size: 1.6rem;
          font-style: italic;
          padding-bottom: 1rem;
          text-align: justify;
        }
      }

      & .certificate-right {
        display: none;
      }
    }
  }

  & .coursedetails-review {
    height: auto;
    width: 100%;
    background-color: #ffd319;
    display: none;

    & h2 {
      color: #333333;
      padding: 4rem 3rem 2rem 3rem;
      font-size: 3rem;
      text-align: center;
      font-weight: 500;
    }

    & .student-review {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      width: 100%;
      height: auto;
      padding: 2rem;
      justify-content: center;
      align-items: center;

      & .content {
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 2rem;
        width: 30%;

        & .review-content {
          display: flex;

          & .quote {
            color: #ffd319;
            font-size: 8rem;
            text-align: left;
            padding: 0 0 0 1rem;
          }

          & .review {
            margin: 4rem 2rem 0 0;
            height: 14rem;
            background-color: white;

            & p {
              height: 14rem;
              overflow: scroll;
              text-align: left;
              font-size: 1.2rem;
            }
          }
        }

        & .sep1 {
          font-size: 3rem;
          text-align: center;
          border-bottom: 0.1rem black solid;
          display: inline-block;
          padding-top: 2rem;
        }

        & .desi {
          display: grid;
          grid-template-columns: 1fr 2fr;

          & img {
            width: 4rem;
            margin: 1rem;
            border-radius: 50%;
          }

          & .footer-heading {
            & .heading-review {
              font-weight: bold;
              padding: 1rem 0 0 0;
            }

            & p {
              text-align: left;
            }
          }
        }
      }
    }
  }

  & .coursedetails-process-mobile {
    display: none;
  }
}

.line_one::before {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "1";
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  background-color: #ffc727;
  left: 10%;
  transform: translateX(-50%);
  font-size: 7rem;
  color: black;
  font-weight: 900;
}

.sshow-me-ccircle:nth-child(n) {
  transform: scale(1, 1);
}

.enroll:hover {
  color: black;
}

@media screen and (max-width: 768px) and (min-width: 120px) {
  .course-css {
    width: 89%;
  }

  .coursedetails-main {
    & .coursedetails-header {
      height: 80vh;

      & h2 {
        font-size: 2.5rem;
      }

      & p {
        font-size: 1rem;
      }
    }

    & .coursedetails-learn {
      & .learn-head {
        /* background-color: #FFC727; */
        flex-direction: column;
        padding: 1rem 0.5rem;

        & h2 {
          font-size: 2rem;
        }

        & img {
          height: 7rem;
        }
      }

      & .tick-learn .learn ul {
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0.1rem 2rem;
      }
    }
    & .coursedetails-process {
      & .learn-process {
        /* background-color: red; */
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;

        & h2 {
          font-size: 2rem;
        }

        & img {
          width: 90%;
          /* background-color: red; */
        }
      }

      & .process-content-images {
        padding: 0 !important;

        & .content-lap {
          display: none;
        }

        & .content-mob {
          display: block;
          margin-bottom: 2rem;
        }
      }

      & .process-content {
        flex-wrap: nowrap;

        & img {
          width: 2.8rem;
        }

        & .project {
          flex-direction: column;

          & svg {
            transform: rotate(90deg);
          }
        }

        & .assignment,
        .practical {
          padding-bottom: 4rem;
        }
      }
    }

    & .coursedetails-highlights {
      & .highlight-bg {
        & .highlights-heading {
          display: flex;
          flex-direction: column;
          text-align: center;

          & h2 {
            font-size: 2rem;
          }

          & img {
            height: 7rem;
          }
        }

        & .highlights-content {
          grid-template-columns: 1fr !important;

          & .highlights-left-content {
            columns: 1 !important;
            margin: 0rem !important;
          }

          & .highlights-right-content {
            flex-direction: column;

            & .duration {
              width: 90% !important;
            }
          }
        }
      }
    }

    & .coursedetails-course-content {
      & .coursedetails-content-header {
        flex-direction: column;
        padding: 0 !important;

        & h2 {
          font-size: 2rem;
        }

        & img {
          width: 10rem;
        }
      }

      & .course-content {
        grid-template-columns: 1fr;

        & .course-css {
          width: 20rem;

          & h4 {
            text-align: center;
            font-size: 1.5rem;
            font-weight: 700;
            /* color: red; */
          }
          & ul li {
            font-size: 1rem;
          }
        }
      }
    }

    & .course-skills {
      & .coursedetails-button {
        display: flex;
        flex-wrap: wrap;

        & button {
          width: 41vw;
        }
      }

      & .skills-heading {
        flex-direction: column;
        padding: 0.5rem 2rem;

        & h2 {
          font-size: 2rem;
        }

        & img {
          width: 8rem;
        }
      }
    }

    & .coursedeatils-certificate {
      & .certificate-heading {
        padding: 1rem !important;

        & h2 {
          font-size: 2.4rem;
          text-align: center;
          font-weight: 600;
        }
      }

      & .certificate-content {
        flex-direction: column;
        padding: 1.5rem;
        text-align: justify;

        & .certificate-left {
          padding: 1rem;
        }
      }
    }

    & .coursedetails-review {
      & h2 {
        font-size: 2rem;
      }

      & .student-review {
        display: flex;
        flex-direction: column;

        & .content {
          width: 100%;

          & .review-content {
            & .quote {
              font-size: 4rem !important;
              padding: 2rem 0 0 1rem;
            }

            & .review {
              & p {
                width: 13rem;
              }
            }
          }
        }
      }
    }

    & .coursedetails-course-content {
      & .course-content {
        & .course-container {
          width: 19rem;
          /* margin: 2rem; */
        }
      }
    }
  }

  & .coursedetails-process-mobile {
    background-color: white;

    & .learn-process {
      & h2 {
        font-size: 2rem;
      }

      & img {
        width: 7rem;
      }

      display: flex;
      justify-content: space-between;
      padding: 2rem 4rem;
      align-items: center;
    }

    & .process-content {
      padding: 2rem 8rem;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      & .arrow-side {
        display: flex;
        align-items: center;

        & svg {
          width: 2rem;
        }
      }

      & .image-title {
        text-align: center;

        & img {
          width: 2rem;
          /* margin: 1rem; */
        }

        & p {
          font-size: 1rem;
        }
      }

      & IoArrowForward {
        width: 5rem;
      }

      & .bottom-process-images {
        /* padding: 2rem 8rem; */
        display: flex;
        flex-direction: row-reverse;
        align-content: center;
        justify-content: center;
        align-items: center;

        & .arrow-side {
          & svg {
            transform: rotate(180deg);
          }
        }

        /* flex-wrap: wrap; */
      }

      & .up-process-images {
        /* padding: 2rem 8rem; */
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & .coursedetails-process-mobile {
    display: block !important;

    & .process-content {
      flex-direction: column;

      & .project {
        flex-direction: column;

        & svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  & .course-skills {
    display: none;
  }
}

@media screen and (max-width: 1320px) and (min-width: 768px) {
  .coursedetails-main {
    & .coursedetails-process-mobile {
      background-color: white;

      & .learn-process {
        display: flex;
        justify-content: space-between;
        padding: 2rem 4rem;
        align-items: center;
      }

      & .process-content {
        padding: 2rem 8rem;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        & .assignment,
        .practical {
          padding-bottom: 4rem;
        }

        & .arrow-side {
          display: flex;
          align-items: center;
        }

        & .image-title {
          text-align: center;

          & img {
            margin: 1.2rem;
          }
        }

        & IoArrowForward {
          width: 5rem;
        }

        & .bottom-process-images {
          /* padding: 2rem 8rem; */
          display: flex;
          flex-direction: row-reverse;
          align-content: center;
          justify-content: center;
          align-items: center;

          & .arrow-side {
            & svg {
              transform: rotate(180deg);
            }
          }

          /* flex-wrap: wrap; */
        }

        & .up-process-images {
          /* padding: 2rem 8rem; */
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
        }
      }
    }

    & .coursedetails-process-mobile {
      display: block;

      & .process-content {
        flex-direction: column;

        & .project {
          flex-direction: column;

          & svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
