.student-main {
  text-align: center;
  font-family: "Figtree", sans-serif !important;

  & .sep {
    font-size: 2.5rem;
    text-align: center;
    border-bottom: 0.2rem #c3fffa solid;
    display: inline-block;
  }

  & .review-container {
    display: flex;
    gap: 3rem;
    height: auto;
    padding: 2rem 5rem 6rem 5rem;
    justify-content: space-around;
    align-items: center;

    & .content {
      background-color: #c3fffa;
      display: flex;
      flex-direction: column;
      border-radius: 2rem;
      width: 100%;

      & .review-content {
        display: flex;
        flex-direction: column;

        & .quote {
          color: black;
          font-size: 3rem;
          height: 2.5rem;
          text-align: left;
          padding: 0 0 0 1rem;
        }

        & .review {
          height: 13rem;
          background-color: #c3fffa;

          & p {
            height: 13rem;
            scrollbar-width: thin;
            padding: 1rem 1.5rem;
            text-align: justify;
            font-size: 1rem;
          }
        }
      }

      & .sep1 {
        font-size: 3rem;
        text-align: center;
        border-bottom: 0.1rem black solid;
        display: inline-block;
        width: 100%;
      }

      & .desi {
        display: grid;
        grid-template-columns: 1fr 2fr;
        background: linear-gradient(180deg, #50abff 0%, #00315e 100%);
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color: white;

        & img {
          width: 4rem;
          margin: 1rem;
          border-radius: 50%;
        }

        & .footer-heading {
          & .heading-review {
            font-weight: bold;
            padding: 1rem 0 0 0;
          }

          & p {
            text-align: justify;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .student-main {
    & .review-container {
      flex-direction: column;

      & .content {
        width: 90vw;

        & .review-content {
          & .quote {
            font-size: 3rem;
            height: 2.2rem;
          }

          & .review {
            padding: 1.2rem;
            & p {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 321px) {
  .student-main {
    width: 100%;
  }

  .review > p {
    /* width: 7.3rem; */
  }
}

@media screen and (min-width: 120px) and (max-width: 768px) {
  .student-main {
    & .sep {
      font-size: 2.2rem;
    }
    & .review-container {
      gap: 1.5rem;
    }
  }
}
