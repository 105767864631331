/* import logo from 'assets/' */
.container {
    display: flex;
    flex-direction: row;
    background-image: url("./../assests/home1.png");
    background-repeat: no-repeat;
    max-width: 100%;
    width: 180rem;
    height: calc(100vh - 85px);
    background-size: cover;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin: 0;
    background-size: cover;
    padding: 5%;
}



#imageContainer {
    padding-top: 2rem;
    width: 30%;
}

.Get-in-btn {
    text-decoration: none;
    color: black;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.1s linear;
}

.touch_h1 {
    font-size: 4rem;
    color: #ffffff;
}

.touch_para {
    color: white;
    font-size: var(--dp);
    padding-bottom: 2rem;
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s ease;
}

.scrolling {
    animation: slideInFromLeft 5s ease forwards;
}

.touch_btn {
    height: 52px;
    width: 150px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    border-radius: 18px;
    border: 0px;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

#imageContainer img {
    max-width: 100%;
    height: auto;
    transition: transform 0.7s ease-in-out;
}

#imageContainer img:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 1023px) and (min-width: 770px) {
    .container {
        overflow-y: hidden;
        height: 60vh;
        width: 100%;
    }

    .touch_h1 {
        font-size: 3rem;
    }

    #textContainer {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    #imageContainer img {
        display: none;
    }
}

@media screen and (max-width: 769px) {
    .container {
        gap: 0;
        height: 60vh;
        text-align: center;
    }

    .touch_h1 {
        font-size: 2.7rem;
    }

    #imageContainer {
        width: 0;
    }

    #imageContainer img {
        display: none;
    }
}

@media screen and (max-width: 426px) {
    .container {
        flex-direction: column;
        text-align: center;
        height: 60vh;
    }

    .touch_h1 {
        font-size: 2rem;
    }

    .touch_para {
        text-align: center;
    }

    #imageContainer img {
        max-width: 90%;
        height: auto;
    }
}