/* Below code is for EditCourseData */

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    & .box {
        border: .2rem black solid;
        min-width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.text-center {
    text-align: center;
}
select{
    display: block;
}