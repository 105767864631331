.sep2 {
    /* width: 1rem; */
    background-color: black;
    height: .1rem;
    width: 38rem;
    align-items: center;
    text-align: center;
    margin: auto;
}

.roadmap {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    align-items: center;
    background-color: #c3fffa;

    & img {
        width: 70%;
    }

}

.rodmap-heading {
    margin: .1rem 0 1.68rem 0;
    padding: 1rem 0;
    font-size: 2.5rem;

}

.image-mobile {
    display: none;
}


@media screen and (max-width: 1025px) {
    .roadmap>img {
        width: 85%;

    }

  
}

@media screen and (max-width: 426px) {
    .sep2 {
        width: 12rem;
    }


}

@media screen and (min-width: 120px) and (max-device-width: 768px) {

    .roadmap {
        & h1 {
            padding-top: 1rem;
            font-size: 2.2rem;
        }

        & .image-mobile {
            display: block;
            width: 90% ;
        }

        & .image-laptop {
            display: none;
        }
        & .sep2{
            display: none;
        }
    }

}