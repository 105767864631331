.offer-container {
  background-color: #c3fffa;
  max-width: 100%;
  width: 100vw;
  color: #000000;
  text-align: center;
  padding-top: 2rem;
  font-family: "Figtree", sans-serif !important;
  & .sep {
    font-size: 3rem;
    text-align: center;
    border-bottom: 0.1rem #263238 solid;
    display: inline;
    color: #000000;
  }

  & .offer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding: 1rem 0;
    text-align: center;
  }
}

.offer-first {
  text-align: center;
  width: 25rem;

  & h2 {
    font-size: 2rem;
  }

  & p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 120px) and (max-device-width: 768px) {
  /* STYLES HERE */
  .offer-container {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .offer-content {
      gap: 0rem;
      padding: 0rem;
    }

    & .sep {
      /* font-size: 3rem; */
      font-size: 2.5rem;
    }
  }

  .offer-first {
    & h2 {
      padding-bottom: 0rem;
      font-size: 2rem;
    }
  }
}
