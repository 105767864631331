.footer-main {
  background-color: #c3fffa;
  height: auto;
  width: 100%;
  font-family: "Figtree", sans-serif !important;
  & a {
    text-decoration: none;
    color: #000000;
  }

  & .footer-container {
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 0.7fr 1fr;
    justify-content: center;
    padding: 1rem;

    & .footer-first {
      padding: 0.5rem 1rem;
      color: white;

      & .logo {
        width: 8rem;
        margin-bottom: 0.5rem;
      }

      & p {
        font-size: 1rem;
        color: #000000;
      }

      & .sep {
        width: 8rem;
        height: 1px;
        background-color: #000000;
      }

      & .footer-social ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-top: 1rem;
        gap: 0.5rem;

        & img {
          height: 2.4rem;
          aspect-ratio: 1;
          gap: 2rem;
        }
      }
    }

    & .footer-second {
      /* background-color: aqua; */
      color: white;

      & h2 {
        font-size: 36px;
        font-weight: 600;
        padding-bottom: 1rem;
        color: #000000;
        margin: 0;
      }

      & .footer-menu ul li {
        list-style: none;
        font-size: 1rem;
        padding-bottom: 0.5rem;
      }
    }

    & .footer-third {
      /* background-color: aqua; */
      color: white;

      & h2 {
        font-size: 36px;
        font-weight: 600;
        padding-bottom: 1rem;
        color: #000000;
        margin: 0;
      }

      & .footer-menu ul li {
        list-style: none;
        font-size: 1rem;
        padding-bottom: 0.5rem;
      }
    }

    & .footer-forth {
      /* background-color: aqua; */
      /* padding: 0.5rem 1rem; */
      color: white;

      & h2 {
        font-size: 36px;
        font-weight: 600;
        padding-bottom: 1rem;
        color: #000000;
        margin: 0;
      }

      & img {
        height: 3rem;
      }

      & p {
        font-size: 1rem;
        padding-bottom: 0.3rem;
        color: #000000;
      }

      & .sep {
        width: 12rem;
        height: 1px;
        background-color: #000000;
      }

      & .footer-social ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-top: 1rem;
        gap: 0.5rem;

        & img {
          height: 2.4rem;
          aspect-ratio: 1;
          gap: 2rem;
        }
      }

      & .footer-email {
        margin-top: 1rem;
      }

      & .footer-address {
        margin-top: 1rem;
      }
      .footer-contacts {
        display: flex;
        flex-direction: column;
        /* align-items: flex-end; */
        /* justify-content: space-between; */
        gap: 5%;
      }
    }
  }
}

@media (width <=768px) {
  .footer-main {
    .footer-container {
      grid-template-columns: 1fr;

      .footer-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }

  .sep1 {
    width: 12rem;
    margin: auto;
    height: 0.1rem;
    background-color: white;
  }
  .footer-third {
    display: none !important;
  }
  .footer-forth {
    display: none !important;
  }
  & .footer-second {
    & h2 {
      display: none;
    }
  }
}

* {
  margin: 0;
  padding: 0;
}
