.why-main {
  background-color: #ffff;
  width: 100%;
  color: #00000000;
  text-align: center;
  font-family: "Figtree", sans-serif !important;
}
.why-img {
  width: 60%;
}
.underline {
  color: black;
  font-size: 2.5rem;
  text-align: center;
  border-bottom: 0.1rem #000 solid;
  display: inline-block;
}

.why-content {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  position: relative;

  & .logo-why {
    position: absolute;
    display: block;
    width: 10rem;
  }

  & .why1 {
    position: absolute;
    display: block;
    top: 7.5rem;
    left: 49rem;
  }

  & .why2 {
    position: absolute;
    display: block;
    z-index: 5;
    top: 18.5rem;
    left: 56rem;
  }

  & .why3 {
    position: absolute;
    display: block;
    z-index: 5;
    top: 28rem;
    left: 49rem;
  }

  & .why4 {
    position: absolute;
    display: block;
    z-index: 5;
    top: 30rem;
    left: 35rem;
  }

  & .why5 {
    position: absolute;
    display: block;
    z-index: 5;
    top: 19rem;
    left: 28rem;
  }

  & .why6 {
    position: absolute;
    display: block;
    z-index: 5;
    top: 8rem;
    left: 35rem;
  }
}

.why-footer {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  & button {
    background-color: white;
    font-size: 2rem;
    border: none;
    padding: 1rem;
    border-radius: 2rem;
  }
}

.why_choose_logo {
  text-align: center;
  background-color: #ffff;
}
.image-mobile {
  display: none;
}
@media screen and (max-width: 426px) {
  .why_choose_logo > img {
    /* width: 100%; */
  }
}

@media screen and (max-width: 769px) {
  .why_choose_logo > img {
    width: 100%;
    height: 15rem;
  }
  & .image-mobile {
    display: block;
    width: 90%;
  }

  & .image-laptop {
    display: none;
  }
}

@media screen and (max-width: 1025px) {
  .why_choose_logo > img {
    width: 100%;
    /* -webkit-transform: scale(1.5); */
    /* transform: scale(2.1); 
   -webkit-transform-origin: center;
    transform-origin: center; */
  }
}

@media screen and (min-width: 120px) and (max-device-width: 768px) {
  .why_choose_logo {
    & h2 {
      font-size: 2.2rem;
    }
  }
}
